import PropTypes from 'prop-types';
import { flow } from 'lodash';
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import Head from 'next/head';
import supports from 'utils/supports';
import * as seo from 'utils/seo';
import { getPathname } from 'services/site';
import * as dataLayer from 'services/dataLayer';
import { recordEvent } from 'services/recordEvent';
import withRouteData from 'hoc/withRouteData';
import withRouter from 'hoc/withRouter';
import { useMode } from 'context/ModeProvider';
import { Footer } from 'modules/shared/components/footer';
import { HeaderController, Header, useHeader } from 'modules/shared';
import { UserLogoutModal } from 'modules/user';
import { Bisquits } from '../Bisquits';
import HrefLangs from './HrefLangs';
import Breadcrumbs from './Breadcrumbs';
import { Toaster } from './Toast';
import styles from './Page.module.scss';

const DEFAULT_PAGE_OFFSET = 64;

const Page = ({
  className,
  htmlAttributes,
  title,
  description,
  metaTitle,
  metaDescription,
  metaImage,
  breadcrumbs: breadcrumbsOrig,
  withoutAuthWall,
  header,
  subHeader,
  footer,
  children,
  routeData: { alternativePaths = {}, headerShown = true, hrefLangs = true },
  router: { location },
}) => {
  const [supportsClipPathShapes, setSupportsClipPathShapes] = useState(false);
  const { isSite } = useMode();
  const { pageOffset } = useHeader();
  const { pathname } = location;

  const stablePageOffset = pageOffset || DEFAULT_PAGE_OFFSET;

  useEffect(() => {
    setSupportsClipPathShapes(supports.clipPathShapes());

    dataLayer.push('virtualPageView', {
      virtualPageViewPath: getPathname(pathname),
    });

    recordEvent('viewPage');
  }, [pathname]);

  let breadcrumbs = breadcrumbsOrig;
  if (breadcrumbs !== false) {
    breadcrumbs = breadcrumbs || title;
  }

  return (
    <>
      <Head htmlAttributes={htmlAttributes}>
        {title && <title key="title">{seo.formatTitle(title)}</title>}
        {(metaTitle || title) && (
          <meta property="og:title" content={seo.formatTitle(metaTitle || title)} key="ogTitle" />
        )}
        {description && <meta name="description" content={description} key="description" />}
        {metaDescription && (
          <meta property="og:description" content={metaDescription} key="ogDescription" />
        )}
        {metaImage && <meta property="og:image" content={metaImage} key="ogImage" />}
      </Head>
      {hrefLangs && <HrefLangs pages={alternativePaths} />}

      {headerShown && (
        <div
          className={styles.top}
          style={{
            '--pageOffset': `${stablePageOffset}px`,
          }}
        >
          <HeaderController header={isSite && header} />
        </div>
      )}

      {subHeader}

      <main
        className={classnames(
          styles.root,
          supportsClipPathShapes ? 'clipPathShapes' : 'noClipPathShapes',
          className,
        )}
        style={{ paddingTop: isSite ? `${stablePageOffset}px` : 0 }}
      >
        {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs || title} />}
        {children}
      </main>
      {isSite && (
        <>
          <div className={styles.footer}>{footer}</div>
          <Bisquits />
          <Toaster />
        </>
      )}

      <UserLogoutModal withoutAuthWall={withoutAuthWall} />
    </>
  );
};

Page.propTypes = {
  className: PropTypes.string,
  htmlAttributes: PropTypes.shape(),
  title: PropTypes.string,
  description: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: PropTypes.string,
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])),
  ]),
  withoutAuthWall: PropTypes.bool,
  header: PropTypes.node,
  subHeader: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  routeData: PropTypes.shape({
    alternativePaths: PropTypes.shape(),
    hrefLangs: PropTypes.bool,
    headerShown: PropTypes.bool,
  }).isRequired,
  router: PropTypes.shape().isRequired,
};

Page.defaultProps = {
  className: undefined,
  htmlAttributes: {},
  title: undefined,
  description: undefined,
  metaTitle: undefined,
  metaDescription: undefined,
  metaImage: undefined,
  breadcrumbs: undefined,
  withoutAuthWall: false,
  header: <Header />,
  subHeader: undefined,
  footer: <Footer />,
};

export default flow(withRouteData, withRouter)(Page);
